<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'上传':'审核'" :visible.sync="show" width="600px" class="selfInputBox">





        <div v-if="isEdit==true">



            <!-- <el-upload drag class="" action="/api/invoice/uploadImage/" :headers="Headers" name="image"
                list-type="picture-card" :limit="10" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload" :file-list="photoList" :show-file-list="true">
            </el-upload> -->

            <!-- examineInfo -->



            <el-upload drag class="uploadImg" :action="'/api/invoice/uploadImage/'+examineInfo.id" :headers="Headers"
                name="image" list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError" :on-remove="handleRemove" :before-upload="beforeAvatarUpload"
                :file-list="photoList" :show-file-list="true">
                <!-- <i class="el-icon-upload"></i> -->
                <div class="el-upload__text">
                    <p>将文件拖到此处</p>
                    <p>或<em>点击上传</em></p>
                    <p>只能上传jpg/png文件</p>
                    <p>且不超过500kb</p>
                </div>
            </el-upload>





            <!-- <span slot="footer">
                <div class="buttons">
                    <el-button type="primary" @click="save">保存</el-button>
                </div>
            </span> -->



        </div>


        <div v-if="isEdit==false">




            <el-form ref="form" label-width="80px">


                <el-form-item class="must" label="审核" prop="">

                    <div class="radioBox">


                        <!-- 状态（0待审核、1待开票、2已开票、3已拒绝, ） -->
                        <!-- <el-radio v-model="upDatavalue.status" label="0">待审核</el-radio>
                    <el-radio v-model="upDatavalue.status" label="1">待开票</el-radio>
                    <el-radio v-model="upDatavalue.status" label="2">已开票</el-radio>
                    <el-radio v-model="upDatavalue.status" label="3">已拒绝</el-radio> -->

                        <el-radio v-model="upDatavalue.status" label="1">通过</el-radio>
                        <el-radio v-model="upDatavalue.status" label="3">拒绝</el-radio>

                    </div>


                </el-form-item>

                <el-form-item label="原因" prop="">
                    <el-input v-model="upDatavalue.refuse_reason" />
                </el-form-item>

            </el-form>

        </div>




        <span slot="footer" v-if="isEdit==false">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
            </div>
        </span>




    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {

        },
        data() {
            return {
                show: false,

                // // 回显图片的路径
                photoList: [],
                // // 图片的路径
                // dialogImageUrl: "",

                isEdit: false,


                examineInfo: {},


                upDatavalue: {
                    status: "",
                    refuse_reason: ""
                },


            }
        },


        watch: {

            show(value) {
                if (this.show) {

                } else {
                    this.isEdit = true

                    this.examineInfo = {}

                    this.upDatavalue = {
                        status: "",
                        refuse_reason: ""
                    }

                }
            },

        },

        computed: {

            Headers() {
                let token = localStorage.getItem("token");
                return {
                    token
                }
            }

        },

        methods: {





            open(val) {
                this.examineInfo = {
                    ...val
                }
                this.isEdit = false

                this.show = true
            },

            edit(val) {
                this.examineInfo = {
                    ...val
                }
                this.isEdit = true

                this.show = true

            },






            save() {

                this.$http.put('/api//invoice/setStatus/' + this.examineInfo.id, this.upDatavalue).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.show = false
                        this.$emit('refresh')

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });


            },














            handleRemove(file, fileList) {
                console.log(file, fileList)
                // this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {

                console.log("res, fileres, fileres, fileres, file", res, file);

                if (res.code != 200) { //请求错误
                    this.$message.error(res.msg);
                }
                if (res.code == 200) {
                    this.show = false
                    this.$emit('refresh')

                    this.$message.success(res.msg);
                }



                // this.dialogImageUrl = res.data
                // console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!')
                }
                return /* isJPG && */ isLt2M
            },

            handleAvatarError(err, file, fileList) {

                console.log("err, file, fileList", err, file, fileList);


                if (res.code != 200) { //请求错误
                    this.$message.error(res.msg);
                }
                if (res.code == 200) {
                    this.show = false
                    this.$emit('refresh')

                    this.$message.success(res.msg);
                }


                // this.dialogImageUrl = res.data
                // console.log(this.dialogImageUrl)
            },









        }
    }
</script>


<style lang="scss" scoped>
    // .selfInputBox {
    // }

    // /deep/.el-input__suffix {
    //     // border: 1px red solid !important;
    //     // box-sizing: content-box !important;
    //     position: relative;
    //     right: 10px;
    // }

    // /deep/.el-input__prefix {
    //     // border: 1px red solid !important;
    //     // box-sizing: content-box !important;
    //     position: absolute;
    //     left: 20px;
    // }

    // .el-select.el-select--mini,
    // .el-date-editor.el-input,
    // .el-date-editor.el-input__inner {
    //     width: 100%;
    // }

    .radioBox {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        padding: 0 20px;

    }

    .uploadImg {
        // border: 1px red solid !important;
        box-sizing: content-box !important;
        position: relative;
        right: -20px;
        width: 148px;
        height: 148px;
        overflow: hidden;

        // line-height: 30px;
        /deep/.el-upload--picture-card {
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload__text {
            line-height: 30px;
            text-align: center;
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload-dragger {
            width: 100%;
            height: 100%;
            // width: 148px;
            // height: 148px;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    }
</style>